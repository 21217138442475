<template>
  <article class="container-content" :class="{ full: isFull }">
    <h6 class="title">{{ title }}</h6>
    <p class="description text-primary">{{ description }}</p>
  </article>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    isFull: {
      type: Boolean,
    },
  },
};
</script>
