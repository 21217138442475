const validateRuc = (ruc) => {
  const rucString = String(ruc).trim();
  const cant = ruc.length;

  let isOk = true;
  let message = '';

  if (!rucString) {
    isOk = false;
    message = 'No se encontró RUC';
  } else if (!/^\d+$/.test(ruc)) {
    isOk = false;
    message = 'El RUC solo debe contener números'
  } else if (cant !== 11) {
    isOk = false;
    message = `El RUC debe tener exactamente 11 dígitos, tiene ${cant}`
  }

  return { isOk, message }
};
export default validateRuc