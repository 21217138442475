
import useCreditBureaus from "@/modules/marketing/composables/useCreditBureaus";
import { computed } from "vue";

const useCreditBureausSentinel = () => {
  const { getSearch } = useCreditBureaus();
  const creditLineUsageResponse = computed(
    () => getSearch?.value?.response?.ConRap?.UtiLinCre || []
  );
  const creditLineUsage = computed(() => {
    return creditLineUsageResponse.value.map(
      ({ Inst, TipCred, LinApr, LinNoUti, LinUti }) => [
        Inst || "-",
        TipCred || "-",
        LinApr || "-",
        LinNoUti || "-",
        LinUti || "-",
        "-",
      ]
    );
  });
  const protestedDocuments = computed(
    () => getSearch?.value?.response?.ConRap?.ResDocPro || []
  );
  const documentsRegularized = computed(() =>
    protestedDocuments.value
      .filter(({ EstPro }) => EstPro === "Regularizado")
      .map(({ NroPro, MonPro, MaxDia }) => [
        NroPro || "-",
        MonPro || "-",
        MaxDia || "-",
      ])
  );
  const documentsNotRegularized = computed(() =>
    protestedDocuments.value
      .filter(({ EstPro }) => EstPro === "Sin Regularizar")
      .map(({ NroPro, MonPro, MaxDia }) => [
        NroPro || "-",
        MonPro || "-",
        MaxDia || "-",
      ])
  );

  return {
    getSearch,
    creditLineUsage,
    documentsRegularized,
    documentsNotRegularized,
  };
}

export default useCreditBureausSentinel