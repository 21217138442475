import { computed } from "vue";
import { toast } from "vue3-toastify";
import { useStore } from "vuex";
import validateRuc from '@/utils/validations/validateRuc';
import months from "@/constants/months";

const useCreditBureaus = () => {
  const getSearch = computed(() => {
    return store.getters["marketing/creditBureausSearch"];
  });

  const trafficLight = computed(() => {
    return store.getters['marketing/creditBureausTrafficLight']
  });

  const store = useStore();

  const getColorByValue = (value) => {
    const colorMapping = {
      100: "#cc1d1d",
      200: "#e5c656",
      300: "#6a9386",
      400: "#21ae81",
    };
    return colorMapping[value] || "";
  };

  const generateChart = () => {

    const {
      ConRap: { DeuSBSMicro = {}, Resumen_ConRap = {} } = {},
    } = getSearch.value?.response;

    if (Object.values(DeuSBSMicro).length > 0) {
      const calculateTotalSalDeu = (entidades) =>
        entidades.reduce((acc, entidad) => acc + parseFloat(entidad.SalDeu), 0);

      const semaphoreValueMapping = {
        R: 100,
        A: 200,
        G: 300,
        V: 400,
      };

      const arr = Resumen_ConRap.Semaforos.split("").map(
        (item) => semaphoreValueMapping[item]
      );

      DeuSBSMicro.forEach((item) => {
        item.TotalSalDeu = calculateTotalSalDeu(item.Entidad).toFixed(2);
      });
      const reverseData = [...DeuSBSMicro].reverse();
      const formattedData = DeuSBSMicro.map(
        (item) => `${months[item.Mes - 1]}-${item.Ano}`
      );

      const handleDataPointSelection = (config) => {
        const currentIndex = config.dataPointIndex;
        const indices = [
          currentIndex,
          currentIndex - 1,
          currentIndex - 2,
          currentIndex - 3,
        ].filter((index) => index >= 0);
        const debtDetail = indices.map((index) => ({
          resultado: reverseData[index],
        }));

        store.commit("marketing/creditBureausTrafficLight", { debtDetail });
      };

      const charts = {
        chart: {
          toolbar: { show: false },
          height: 170,
          type: "line",
          zoom: { enabled: false },
          events: {
            dataPointSelection: (event, chartContext, config) =>
              handleDataPointSelection(config),
            mounted: (chartContext) =>
              handleDataPointSelection({
                dataPointIndex: chartContext.w.config.series[0].data.length - 1,
              }),
          },
        },
        stroke: {
          colors: ["#A9A9A9"],
          width: 4,
          dashArray: 5,
        },
        tooltip: {
          enabled: true,
          intersect: false,
          shared: true,
          followCursor: true,
          x: { show: false },
          y: {
            formatter: (value, { dataPointIndex }) => {
              const totalSalDeu = reverseData[dataPointIndex].TotalSalDeu;
              const formatter = new Intl.NumberFormat("es-PE", {
                style: "currency",
                currency: "PEN",
                minimumFractionDigits: 2,
              });
              return `Monto Total: ${formatter.format(totalSalDeu)}`;
            },
          },
          marker: { show: false },
          offsetY: 10,
        },
        xaxis: {
          categories: formattedData,
        },
        yaxis: {
          min: 100,
          max: 400,
          tickAmount: 3,
          labels: {
            style: {
              colors: ["#ffffff"],
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
          },
        },
        markers: {
          size: 5,
          discrete: arr.map((value, index) => ({
            seriesIndex: 0,
            dataPointIndex: index,
            fillColor: getColorByValue(value),
            strokeColor: "#7cb5ec",
            strokeWidth: 15,
            strokeOpacity: 0.9,
            size: 8,
          })),
        },
      };

      const series = [
        {
          name: "",
          data: arr,
        },
      ];

      store.commit("marketing/creditBureausTrafficLight", { charts, series });
    }
  };

  const search = async () => {
    const ruc = store.getters["marketing/creditBureausSearch"].ruc;

    try {
      const { isOk, message } = validateRuc(ruc);

      if (!isOk) {
        toast.error(message);
        return;
      }

      store.commit("marketing/creditBureausSearch", { isLoading: true });

      const responseToken = await store.dispatch('marketing/searchSentinelToken');
      const token = responseToken?.access_token;

      if (token) {
        const responseHistory = await store.dispatch('marketing/searchSentinel', {
          ruc, token
        });
        store.commit('marketing/creditBureausSearch', { response: responseHistory.respuesta });
        const responseBehavior = await store.dispatch("marketing/searchBehavior", {
          ruc,
        });
        store.commit('marketing/creditBureausTrafficLight', { data: responseBehavior })
        generateChart();
      }

    } catch (error) {
      console.error('Error al buscar el token:', error);
      toast.error('Ocurrió un error inesperado');
    } finally {
      store.commit("marketing/creditBureausSearch", { isLoading: false });
    }

  }



  return { getSearch, search, trafficLight }
}
export default useCreditBureaus;