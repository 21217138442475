<template>
  <DashboardLayout>
    <main class="file-container">
      <section class="file-content">
        <article class="form-content">
          <Header title="Central de Riesgos" />
          <FormSearch />
          <LoadingComponent v-if="getSearch.isLoading" />

          <template
            v-else-if="
              !getSearch.isLoading &&
              Object.values(getSearch.response).length > 0
            "
          >
            <TrafficLight />
            <TableDebtDetails />
            <DataOverview />

            <TableCreditBureaus
              title="Utilización de Líneas de Crédito"
              :headers="[
                'Instituciones',
                '**',
                'Línea Aprobada',
                'Línea No Utilizada',
                'Línea Utilizada',
                '% L. Uti.',
              ]"
              :bodies="[...creditLineUsage]"
              :columns="6"
            >
              <template #footer>
                <p class="footer-table">
                  **(DES): Descuentos, (PRE): Préstamos, (AVP): Avales y Fianzas
                </p>
              </template>
            </TableCreditBureaus>
            <TableCreditBureaus
              title="Documentos Protestados sin Regularizar"
              :headers="['# Doc.', 'Monto (S/) *', 'Max Días Venc.']"
              :bodies="[...documentsNotRegularized]"
              firstColumnColor
              textFirstColumn="Total"
              :columns="4"
              isTableBorder
              :hasMarginBottom="false"
            />

            <TableCreditBureaus
              title="Documentos Protestados Regularizados"
              :headers="['# Doc.', 'Monto (S/) *', 'Max Días Venc.']"
              :bodies="[...documentsRegularized]"
              firstColumnColor
              textFirstColumn="Total"
              :columns="4"
              isTableBorder
              :hasMarginBottom="false"
            />
          </template>
        </article>
      </section>
    </main>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/modules/shared/layouts/DashboardLayout";
import Header from "@/modules/shared/layouts/Header";
import HeaderTable from "@/modules/shared/components/HeaderTableCreditBureaus";
import FooterTable from "@/modules/shared/components/FooterTableCreditBureaus";
import TableCreditBureaus from "@/modules/shared/components/Table";
import LoadingComponent from "@/components/LoadingComponent";

import TableDebtDetails from "@/modules/marketing/components/creditBureaus/TableDebtDetails";
import TrafficLight from "@/modules/marketing/components/creditBureaus/TrafficLight";

import FormSearch from "@/modules/marketing/components/creditBureaus/FormSearch";
import DataOverview from "@/modules/marketing/components/creditBureaus/DataOverview";
import useCreditBureausSentinel from "@/modules/marketing/composables/useCreditBureausSentinel";

export default {
  components: {
    DashboardLayout,
    Header,
    FormSearch,
    DataOverview,
    TableCreditBureaus,
    LoadingComponent,
    HeaderTable,
    FooterTable,
    TableDebtDetails,
    TrafficLight,
  },

  setup() {
    const {
      getSearch,
      creditLineUsage,
      documentsRegularized,
      documentsNotRegularized,
    } = useCreditBureausSentinel();

    return {
      getSearch,
      creditLineUsage,
      documentsRegularized,
      documentsNotRegularized,
    };
  },
};
</script>
