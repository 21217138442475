<template>
  <form class="form-search" @submit.prevent="search">
    <input
      class="search-input"
      type="text"
      required
      placeholder="RUC"
      v-model="getSearch.ruc"
    />

    <button
      class="btn btn-primary btn-next xs-none"
      type="submit"
      :disabled="getSearch.isLoading"
    >
      <p>Buscar</p>
    </button>
  </form>
</template>

<script>
import useCreditBureaus from "@/modules/marketing/composables/useCreditBureaus";
export default {
  setup() {
    const { getSearch, search } = useCreditBureaus();
    return {
      getSearch,
      search,
    };
  },
};
</script>
