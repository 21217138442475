<template>
    <header class="text-center">
        <div class="w-md-50 w-lg-25 m-auto">
            <div class="header-user pointer">
                <i class="fas fa-user-circle"></i>
                <p id="name">{{ user.userData.name }}</p>
            </div>
        </div>

        <h1 class="mt-2">{{title}}</h1>
    </header>
</template>

<script>
import { useStore } from 'vuex'
export default {
    props:{
        title: {
            type: String,
            required: true
        }
    },
    setup() {
        const store = useStore();
        return {
            ...store.state,
        }
    }
}
</script>