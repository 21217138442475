import { computed } from "vue";
import { useStore } from "vuex";
import months from "@/constants/months";

const useCreditBureausDebtDetails = () => {
  const store = useStore();

  const getMonthName = (month) => {
    return months[month - 1];
  };
  const creditBureausTrafficLight = computed(() => store.getters["marketing/creditBureausTrafficLight"]);

  const availableMonths = computed(() => {
    const debtDetails = creditBureausTrafficLight.value.debtDetail

    return debtDetails.map(
      ({ resultado }) => getMonthName(resultado.Mes) + " " + resultado.Ano
    ).reverse();
  });
  let totalDebtByMonth = {};
  const entities = computed(() => {
    const debtDetails = creditBureausTrafficLight.value.debtDetail

    let entitiesMap = {};
    let index = 0;
    if (debtDetails && Array.isArray(debtDetails)) {

      debtDetails.forEach(({ resultado }) => {
        if (resultado && Array.isArray(resultado.Entidad)) {
          resultado.Entidad.forEach((entity) => {
            if (!entitiesMap[entity.NomEnt]) {
              entitiesMap[entity.NomEnt] = {
                Name: entity.NomEnt,
                dataByMonth: {},
                Rating: entity.Cal,
                ReportDate: entity.FchPro,
                DebtBalance: entity.SalDeu,
                DaysDue: entity.DiaVen,
              };
            }

            const monthYearKey =
              getMonthName(resultado.Mes) + " " + resultado.Ano;
            totalDebtByMonth[index] = resultado.TotalSalDeu;
            entitiesMap[entity.NomEnt].dataByMonth[monthYearKey] =
              entity.SalDeu;
          });
          index++
        }
      });
    }
    return Object.values(entitiesMap);

  });

  const getDebtByMonth = (entity, month) => {
    return entity.dataByMonth[month] || "-";
  };

  return {
    creditBureausTrafficLight,
    availableMonths,
    entities,
    getMonthName,
    getDebtByMonth,
    totalDebtByMonth
  };
};

export default useCreditBureausDebtDetails;
