<template>
  <div
    class="
      behavior-container
      p-2
      mb-1
      mt-1
      d-flex
      chart-content
      justify-content-between
    "
    v-if="
      Object.values(trafficLight.charts).length > 0 &&
      trafficLight.series.length > 0
    "
  >
    <div class="behavior-content-legends mt-2 d-flex">
      <div class="behavior-content-legends-content d-flex flex-column">
        <VueCustomTooltip
          label="Semáforo Verde:
Sin deudas vencidas."
          ><div class="legend-chart-v"></div
        ></VueCustomTooltip>
        <VueCustomTooltip
          label="Semáforo Gris:
No registra información de deudas."
          ><div class="legend-chart-g"></div
        ></VueCustomTooltip>
        <VueCustomTooltip
          label="Semáforo Amarillo:
Deudas con poco atraso."
          ><div class="legend-chart-a"></div
        ></VueCustomTooltip>
        <VueCustomTooltip
          label="Semáforo Rojo:
 Deudas con atraso significativo."
          ><div class="legend-chart-r"></div
        ></VueCustomTooltip>
      </div>
    </div>

    <div class="apexchart-wrapper flex-grow-1">
      <apexchart
        class="apexchartdiv"
        :options="trafficLight.charts"
        :series="trafficLight.series"
        type="line"
        height="350"
      />
    </div>
  </div>
</template>

<script>
import useCreditBureaus from "@/modules/marketing/composables/useCreditBureaus";
export default {
  setup() {
    const { trafficLight } = useCreditBureaus();

    return {
      trafficLight,
    };
  },
};
</script>
