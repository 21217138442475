<template>
  <div
    class="table-credit-bureaus"
    :class="{
      'table-border': isTableBorder,
      'mb-1': hasMarginBottom,
      'mt-2': hasMarginTop,
    }"
  >
    <slot name="header"></slot>
    <h4 class="text-primary mb-1 table-title" v-if="title">{{ title }}</h4>
    <div
      class="grid-header"
      :style="{
        gridTemplateColumns: firstColumnColor
          ? `100px repeat(${columns - 1}, 1fr)`
          : `repeat(${columns}, 1fr)`,
      }"
    >
      <div class="grid-item header-firstColmun" v-if="firstColumnColor"></div>
      <div class="grid-item" v-for="(header, index) in headers" :key="index">
        {{ header }}
      </div>
    </div>
    <div
      class="grid-body"
      :style="{
        gridTemplateColumns: firstColumnColor
          ? `100px repeat(${columns - 1}, 1fr)`
          : `repeat(${columns}, 1fr)`,
      }"
      v-for="(body, index) in bodies"
      :key="index"
      v-if="bodies.length > 0"
    >
      <div class="grid-item body-firstColmun" v-if="firstColumnColor">
        {{ textFirstColumn }}
      </div>
      <div
        class="grid-item"
        v-for="(field, fieldIndex) in body"
        :key="fieldIndex"
      >
        {{ field }}
      </div>
    </div>
    <div class="grid-body-empty" v-if="bodies.length === 0">
      <p>Sin datos</p>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    columns: {
      type: Number,
      default: 3,
    },
    headers: {
      type: Array,
      required: true,
    },
    bodies: {
      type: Array,
      required: true,
    },
    firstColumnColor: {
      type: Boolean,
      default: false,
    },
    textFirstColumn: {
      type: String,
    },
    isTableBorder: {
      type: Boolean,
      default: false,
    },
    hasMarginBottom: {
      type: Boolean,
      default: true,
    },
    hasMarginTop: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
