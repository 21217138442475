<template>
  <section class="info-container">
    <div class="info-container-dataOverview p-2">
      <h4 class="text-primary">Datos Principales</h4>
      <section class="info-content container-grid-dataOverview mt-1">
        <OverviewItem
          title="Razón Social"
          :description="infoBas.RazSoc || '-'"
          isFull
        />
        <OverviewItem
          title="Nombre Comercial"
          description="FABER-CASTELL"
          :description="infoBas.NomCom || '-'"
          isFull
        />
        <OverviewItem
          title="Tipo de Contribuyente"
          description="SOCIEDAD ANONIMA"
          :description="infoBas.TipCon || '-'"
          isFull
        />
        <OverviewItem
          title="Estado de Contribuyente"
          description="ACTIVO"
          :description="infoBas.EstCon || '-'"
        />
        <OverviewItem
          title="Condición del Contribuyente"
          :description="infoBas.EstDom || '-'"
          description="HABIDO"
        />
        <OverviewItem
          title="Inicio de Actividades"
          :description="infoBas.IniAct ? getFormatDate(infoBas.IniAct) : '-'"
          isFull
        />
        <OverviewItem title="Tamaño contribuyente" description="-" />
        <OverviewItem title="Dependencia" description="-" />
        <OverviewItem
          title="Actividad Económica Principal"
          :description="infoBas.ActEco || '-'"
          isFull
        />
        <OverviewItem
          title="Actividad Económica Secundaria 1"
          description="-"
        />
        <OverviewItem
          title="Actividad Económica Secundaria 2"
          description="-"
        />
        <OverviewItem
          title="Fecha Inscripción RRPP"
          :description="
            infoBas.FchInsRRPP ? getFormatDate(infoBas.FchInsRRPP) : '-'
          "
          description="21/05/1965"
        />
        <OverviewItem
          title="Número de Partida Registral"
          :description="infoBas.NumParReg || '-'"
          description="101636160000"
        />
        <OverviewItem title="Folio" :description="infoBas.Fol || '-'" />
        <OverviewItem title="Asiento" :description="infoBas.Asi || '-'" />
      </section>
    </div>
  </section>
</template>

<script>
import { computed } from "vue";
import OverviewItem from "@/modules/shared/components/OverviewItem";
import useCreditBureaus from "@/modules/marketing/composables/useCreditBureaus";
import getFormatDate from "@/helpers/getFormatDate";

export default {
  components: {
    OverviewItem,
  },
  setup() {
    const { getSearch } = useCreditBureaus();
    const infoBas = computed(() => getSearch?.value?.response?.InfBas || {});
    return {
      infoBas,
      getFormatDate,
    };
  },
};
</script>
